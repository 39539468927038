
let request = window.require('request');//先npm install request
let path = window.require('path');
let fs = window.require('fs');
let os = window.require('os');
let spawn = window.nw.require('child_process').spawn;//eslint-disable-line no-unused-vars
let process = window.nw.require('process');

//let url = 'http://localhost:8080/new.zip';//新版本资源zip网络地址

var updater = {
    checkVersion: version => {
        if (window.nw.App.manifest.version != version) {
            return true;
        }
        return false;
    },
    downLoad: (url,rate,error) => {
        var pkg = request(url, function (err, response) {
            if (err) {
                console.log(err);
                if(error){
                    error(err);
                }
            }
            if (response && (response.statusCode < 200 || response.statusCode >= 300)) {
                pkg.abort();
                if(error){
                    error('下载异常,请重试');
                }
            }
        });

        pkg.on('response', function (response) {
            if (response && response.headers && response.headers['content-length']) {
                pkg['content-length'] = response.headers['content-length'];
            }
        });

        var tempDir = os.tmpdir();
        var filename = path.basename(url),
            destinationPath = path.join(tempDir, filename);
        // console.log('filename', filename)
        // console.log('tempDir', tempDir);
        // console.log('root', window.nw.__dirname);

        // download the package to template folder
        fs.unlink(path.join(tempDir, filename), function () {
            pkg.pipe(fs.createWriteStream(destinationPath));
            pkg.resume();
        });

        let loaded = 0;
        pkg.on('data', chunk => {
            loaded += chunk.length;
            let loading = Math.floor(loaded / pkg['content-length'] * 100);
            console.log(loading);
            if(rate){
                rate(loading);
            }
            //document.getElementById('loading').innerText = loading + '%';
        });

        pkg.on('error', (err) => {
            console.log(err);
            if(error){
                error(err);
            }
        });
        pkg.on('end', appDownloaded);
        pkg.pause();

        function appDownloaded() {
            process.nextTick(function () {
                if (pkg.response.statusCode >= 200 && pkg.response.statusCode < 300) {
                    console.log("ok")
                    //下载完成后调用updater.exe解压至程序所在目录
                    let udtPath = path.resolve(window.nw.__dirname, "updater.exe");//当前应用目录
                    console.log('udtPath:', udtPath);
                    //destinationPath==zip文件所在的目录
                    console.log('destinationPath', destinationPath);
                    console.log(path.dirname(udtPath));
                    var updateProcess = spawn('"' + udtPath + '"', [
                        '--zip-path', '"' + destinationPath + '"',
                        '--dest-path', '"' + window.nw.__dirname + '"',
                        '--app-name', 'wequn.exe'
                    ], {
                        cwd: path.dirname(udtPath),
                        detached: true,
                        stdio: 'ignore',
                        shell: true,
                    });
                    updateProcess.unref();
                    nw.App.quit();
                }
            });
        }
    },

};

export default updater;