<template>
  <div class="indexPage overflow" :class="{'indexActive':notice}" v-cloak>
    <!-- 动画 -->
    <template v-if="isHtml">
      <div class="indexAbsolute drag"></div>
      <div class="indexTop overflow" ref="topContent">
        <!-- <img src="../src/assets/header.jpg" alt="" class="indexBg"> -->
        <!-- 头部菜单 -->
        <div class="indexNav overflow flex flexSpace drag">
          <div class="navLeft overflow flex">
            <img src="../src/assets/logo.png" alt="" class="navLogo" />
            <p class="navTitle colorWhite baseMax">
              加群师微群采集系统
            <div class="notice baseTitle" v-if="notice" ref="noticeRef">
              <vue-seamless-scroll :data="listData" :class-option="optionLeft" class="warp">
                <ul class="item" :style="{width:notice.length * (notice.length>=40?12.5:13) +'px'}">
                  <li v-for="item in listData">{{notice}}</li>
                </ul>
              </vue-seamless-scroll>
              <!-- <vue-seamless-scroll
              :data="listData"
              class="warp"
              :class-option="classOption"
            >
             <div v-for="(item,index) in listData" :key="index">{{notice}}</div>
            </vue-seamless-scroll> -->
            </div>
            <!-- <span class="baseTitle">V{{version}}</span> -->
            </p>
          </div>
          <div class="navRight overflow flex colorWhite">
            <template v-if="integralUser.is_available">
              <span class="baseTitle">当前消耗{{ integralUser.consume_num }}积分</span><span
                class="baseTitle titleMargin">剩余{{
                integralUser.remain_num }}积分</span>
              <!-- <span class="baseTitle">{{ integralUser.days }}天后失效</span> -->
            </template>
            <template v-else>
              <span class="baseTitle">{{ integralUser.msg }}</span>
            </template>
            <span @click="menuClick(0)" class="navSign flex flexCenter cursor">
              <img src="../src/assets/radius.png" />
            </span>
            <img class="navMin cursor" src="../src/assets/window.png" @click="menuClick(1)" v-if="!isMax" />
            <img class="navMin navMax cursor" src="../src/assets/windows.png" @click="menuClick(2)" v-else />
            <img class="navClose cursor" src="../src/assets/close.png" @click="menuClick(3)" />
          </div>
        </div>
        <!-- 导航菜单 -->
        <div class="indexMenu overflow flex">
          <li :class="{ cur: current == item.key }" @click="changeMenu(item.key)" v-for="(item, index) in menu"
            :key="index">
            <img :src="item.src" />
            <p class="colorWhite baseMax">{{ item.name }}</p>
          </li>
        </div>
      </div>
      <!-- 内容 -->
      <!-- :style="{ height: contentH }" -->
      <div class="indexContent overflow">
        <div class="contentBottom overflow">
          <!-- 自定义组件start -->
          <keep-alive>
            <component ref="components" v-bind:is="currentTabComponent" @titleMethod="titleMethod"
              @changeMenu="changeMenu" @collectMethod="collectMethod" @initCount="initCount" @closeCircle="closeCircle"
              @updateDown="updateDown" :downCount="count" @isShield="isShield">
            </component>
          </keep-alive>
          <!-- 自定义组件end -->
        </div>
      </div>
      <!-- 采集动画 -->
      <div class="collectAction" v-if="isCollect" @click.stop="closeMethod">
        <div class="collectContent">
          <div class="collectImg overflow">
            <img src="../src/assets/circle.gif" alt="" />
            <template v-if="!isDownTxt">
              <animate-number :from="initCountNew" :to="count" :key="count" ref="countSum" v-if="!isContinue">
              </animate-number>
              <p class="" v-else>{{ count }}</p>
            </template>
            <!-- <p class="">{{count}}</p> -->
          </div>
          <p class="textCenter collectTitle">
            {{
            !isContinue && !isDown&&!isUpdate&&!isDownTxt
            ? "连接成功，正在采集中..."
            : isDown
            ? `下载中，还剩下${downTotal}条数据未下载...`
            : (isUpdate?'下载中...':(isDownTxt?'努力下载中，请勿关闭...':"暂停中..."))
            }}
          </p>
          <!-- <p class="flex flexCenter collectBtn" v-if="!isDown&&!isUpdate&&!isDownTxt">
            <span class="baseMax cursor" @click.stop="stopMetod(1)" v-if="!isContinue">暂停采集</span>
            <span class="baseMax cursor" @click.stop="resumeMethod(1)" v-else>继续采集</span>
            <span class="baseMax cursor" @click.stop="stopMetod(2)">停止采集</span>
          </p> -->
        </div>
      </div>
      <!-- 小动画 -->
      <div class="collectMin cursor" v-if="isPause" @click="resumeMethod()">
        <img src="../src/assets/circle1.png" alt="" class="collectImg" />
        <p class="colorWhite baseFont">采集</p>
      </div>
      <!-- 协议start -->
      <div class="collectAction agreeAction" v-if="isAgree">
        <div class="agreeContent collectContent">
          <p class="textCenter baseMax">软件使用协议</p>
          <div class="agreeText" v-html="agreeContent">
            <!-- {{agreeContent}} -->
          </div>
          <div class="flex flexCenter collectBtn">
            <span class="baseMax cursor" @click="agreeMethod(1)">暂不使用</span>
            <span class="baseMax cursor agreeBtn colorWhite" @click="agreeMethod(2)">同意并使用</span>
          </div>
        </div>
      </div>
      <!-- 协议 end-->
      <!-- 移动版二维码start -->
      <h5Code ref="code"></h5Code>
      <!-- 移动版二维码end -->
      <!-- 屏蔽词设置start -->
      <downShield ref="shield"></downShield>
      <!-- 屏蔽词设置end -->
      <!-- 我的信息start -->
      <el-dialog title="我的信息" :visible.sync="myMessageVisible" top="35vh" width="30%" custom-class="messageDialog">
        <div class="myMessageContent">
          <li class="flex">
            <p>用户名</p>
            <p>{{info.user_name}}</p>
          </li>
          <li class="flex">
            <p>用户类型</p>
            <p>{{ info.user_type == 0?'普通用户': info.user_type == 1?'渠道用户':info.user_type == 2 ?'代理用户':'付费用户'}}</p>
          </li>
          <li class="flex" v-if="info.user_type == 2">
            <p>到期时间</p>
            <p>{{ info.expired_time}}</p>
          </li>
          <li class="flex">
            <p>注册日期</p>
            <p>{{info.create_time}}</p>
          </li>
          <li class="flex">
            <p>捆绑邮箱</p>
            <p>{{info.email}}<span @click="updateEmail">修改邮箱</span></p>
          </li>
          <li class="flex">
            <p>我的密码</p>
            <p>{{info.pwd}}<span @click="updatePwd">修改密码</span></p>
          </li>
        </div>
      </el-dialog>
      <!-- 我的信息end -->
      <!-- 邀请码start :show-close="agentSetting.is_force_set_invitation_code=='1'?false:true"-->
      <fillInviteCode ref="fillInvite" @exitSoftware="bindInviteMethod('exit')" @sureInvite="bindInviteMethod('sure')"
        @cancleInvite="bindInviteMethod('cancle')" />
      <!-- <el-dialog title="温馨提示" :close-on-click-modal="false" :visible.sync="isShowInvite" top="35vh" width="30%"
        :before-close="cancelInviteMethod" custom-class="inviteDialog" :show-close="false" destroy-on-close>
        <div class="inviteContent">
          <p>{{agentSetting.set_invitation_code_tips}}</p>
          <el-input v-model="inviteCode" placeholder="请填写6位数邀请码"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="bindInviteMethod">确 定</el-button>
            <el-button @click="changeMenu('exit')" v-if="agentSetting.is_force_set_invitation_code=='1'">退出软件
            </el-button>
            <el-button @click="isShowInvite=false" v-else>取消</el-button>
          </span>
        </div>
      </el-dialog> -->
      <!-- 邀请码end -->
      <!-- 邀请码持有者联系方式start -->
      <el-dialog title="联系上级" :close-on-click-modal="false" :visible.sync="isShowInviteContact" top="35vh" width="30%"
        custom-class="inviteDialog" :destroy-on-close="true">
        <myInvitationCode type="myAgent" />
      </el-dialog>
      <!-- 邀请码持有者联系方式end -->
    </template>
    <div class="hidenContent" v-else></div>
    <!-- 锁住用户端start  :show-close="agentSetting.is_force_agent_set_contact=='1'?false:true"-->
    <el-dialog title="代理商必须设置联系方式后才可继续使用本软件" :destroy-on-close='true' :show-close="false" :close-on-click-modal="false"
      :visible.sync="isLock" width="35%" class="lockDialog" top="35vh">
      <!-- <p class="lockTitle">代理商必须设置联系方式后才可继续使用本软件</p> -->
      <div class="lockContent">
        <li><span>微信二维码：</span>
          <upload ref="uploadWx" />
        </li>
        <li><span>微信号：</span>
          <el-input v-model="inputWx" placeholder="请输入微信号"></el-input>
        </li>
      </div>
      <span slot="footer" class="dialogLock-footer">
        <el-button type="primary" @click="sureUpload">确 定</el-button>
        <el-button @click="changeMenu('exit')" v-if="agentSetting.is_force_agent_set_contact=='1'">退出软件</el-button>
        <el-button @click="isLock=false" v-else>取消</el-button>
      </span>
    </el-dialog>
    <!-- 锁住用户端end -->
    <!-- 修改密码start -->
    <el-dialog title="修改密码" :close-on-click-modal="false" :visible.sync="isUpdatePwd" width="30%"
      custom-class="updatePwd" top="35vh">
      <div class="lockContent">
        <li>
          <p>当前密码</p>
          <el-input maxlength="16" v-model="updateParams.currentPwd" placeholder="请输入您的当前密码"></el-input>
        </li>
        <li>
          <p>新密码</p>
          <el-input maxlength="16" v-model="updateParams.newPwd" placeholder="可输入6~16位，含字母,数字"></el-input>
        </li>
        <li>
          <p>确认密码</p>
          <el-input maxlength="16" v-model="updateParams.againPwd" placeholder="请再次输入密码确认"></el-input>
        </li>
      </div>
      <span slot="footer" class="dialogLock-footer">
        <el-button type="primary" @click="sureUpdatePwd">确定修改</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码end-->

    <!-- 修改邮箱start -->
    <el-dialog title="修改邮箱" :close-on-click-modal="false" :visible.sync="isUpdateEmail" width="30%"
      custom-class="updateEmail" top="35vh">
      <div class="lockContent">
        <li>
          <p>当前邮箱</p>
          <div style="font-size: 16px;">{{info.email}}</div>
        </li>
        <li class=" flex flexSpace">
          <p>验证码</p>
          <div class="flex">
            <el-input maxlength="6" v-model="emailParam.emailCode" placeholder="请输入当前邮箱验证码" style="width: 70%;flex:1"></el-input>
            <el-button @click="sendMethod" type="primary" size="medium" style="margin-left: 10px;background-color: #1186ff;border-color: #1186ff;" round:false>{{ sendText }} </el-button>
          </div>
        </li>
        <li>
          <p>新邮箱</p>
          <el-input maxlength="50" v-model="emailParam.email" placeholder="请输入新邮箱"></el-input>
        </li>
      </div>
      <span slot="footer" class="dialogLock-footer">
        <el-button type="primary" @click="sureUpdateEmail">确定修改</el-button>
      </span>
    </el-dialog>
    <!-- 修改邮箱end-->

  </div>
</template>
<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import { MessageBox, Message, Loading } from "element-ui";
  import api from "../http/api";
  import { mapState } from "vuex";
  import { openPath, closeWin, restore, maxWin, minWin, getPath } from "../utils/win";
  import { getUser, getMacMethod,checkOut} from "../utils";
  import updater from '../utils/update.js';
  import router from '../router';

  export default {
    data() {
      return {
        isUpdatePwd: false,//修改密码
        isUpdateEmail:false, //修改邮箱
        timer1: null,
        isDisabled: true, //发送按钮是否禁用
        sendText: '发送验证码',//发送
        updateParams: {
          currentPwd: '', //当前
          newPwd: '',  //新密码
          againPwd: '', //再次确认
        },
        emailParam:{
          emailCode:'',
          email:''
        },
        inputWx: '',//微信号
        isLock: false,//是否锁住用户端
        isShowInviteContact: false,//邀请码持有者
        inviteCode: '',//邀请码
        isShowInvite: false,//是否出现邀请码
        myMessageVisible: false,//是否出现我的信息弹窗
        listData: [1],
        notice: '',//公告
        listerTimer: '',//心跳定时
        isHtml: false,
        isDownTxt: false,//是否是下载txt
        isUpdate: '',//是否更新
        downTotal: "", //下载总条数
        isDown: false, //是否下载
        isContinue: false, //是否恢复采集
        isCollect: false, //是否在采集
        isPause: false, //是否采集暂停
        initCountNew: 0, //初始化count
        count: 0, //采集条数
        countTimer: "", //时间定时器
        current: "wxcollect",
        contentH: 0, //内容高度
        currentTabComponent: "wxcollect", //默认当前组件
        screenWidth: "", //屏幕高度
        topH: "", //监听头部高度
        timer: false, //
        isMax: false, //最小化
        updateVision: '',//更新版本
        version: '',//版本号
        isAgree: false,//是否同意协议
        agreeContent: '',//协议内容
        alertIndex: 0,//
        menu: [
          {
            key: "wxcollect",
            src: require("../src/assets/wx.png"),
            name: "微群采集",
          },
          {
            key: "recharge",
            src: require("../src/assets/money.png"),
            name: "积分充值",
          },  
          {
            key: "uselist",
            src: require("../src/assets/use.png"),
            name: "使用明细",
          },
          {
            key: "code",
            src: require("../src/assets/tel.png"),
            name: "手机版",
          },
          {
            key: "contactSuperiors",
            src: require("../src/assets/contact.png"),
            name: "联系上级",
          },
          {
            key: "agencyCenter",
            src: require("../src/assets/agent.png"),
            name: "代理中心",
          },
          // {
          //   key: "downsetting",
          //   src: require("../src/assets/down.png"),
          //   name: "下载设置",
          // },
          {
            key: "handbook",
            src: require("../src/assets/usesc.png"),
            name: "使用手册",
          },
          {
            key: "submit",
            src: require("../src/assets/sumbit.png"),
            name: "提交需求",
          },

          {
            key: "state",
            src: require("../src/assets/disclaimers.png"),
            name: "免责声明",
          },
          {
            key: "myMessage",
            src: require("../src/assets/myMessage.png"),
            name: "账号信息",
          },
          {
            key: "exit",
            src: require("../src/assets/exit.png"),
            name: "账号退出",
          },
          // {
          //   key: "channel",
          //   src: require("../src/assets/upload.png"),
          //   name: "渠道后台",
          // },
        ],
      };
    },
    computed: {
      optionLeft() {
        return {
          direction: 2,
          limitMoveNum: 1
        }
      },
      ...mapState(["isRechange", "info", "integralUser", "rechangeMsg", 'currentAgentSet', 'agentSetting']), //
    },

    components: {
      vueSeamlessScroll,
      upload: () => import('../src/components/upload.vue'),
      myInvitationCode: () => import("../src/components/myInvitationCode"),
      downShield: () => import("../src/components/downShield"),
      h5Code: () => import("../src/components/h5Code"),
      wxcollect: () => import("../src/components/wxcollect"),
      state: () => import("../src/components/statement"),
      submit: () => import("../src/components/submit"),
      downsetting: () => import("../src/components/downsetting"),
      recharge: () => import("../src/components/recharge"),
      uselist: () => import("../src/components/uselist"),
      handbook: () => import("../src/components/usehandbook"),
      channel: () => import("../src/components/channel"),
      agencyCenter: () => import("../src/components/agencyCenter"),
      fillInviteCode: () => import("../src/components/fillInviteCode"),
    },
    async created() {
      this.version = top.window.require ? window.nw.App.manifest.version : ''
      this.getGroupSet();
      this.getInfo();
      this.getNotice();
      // !localStorage.getItem('isAgree') && this.getAgree();
      if (localStorage.getItem('token') && localStorage.getItem('isAgree')) {
        //console.log('检查用户11111---')
        //this.checkUpdate();
      }
      this.isHtml = true;
    },
    mounted() {
      this.listenMethod()
      //监听控制台报错
      //   window.onerror = function (message, filename, lineno, colno, error) {
      //     console.log('控制台error',error)
      //     top.window.require&&error&&logMethod(error)
      //  }
    },
    beforeDestroy() {
      this.listerTimer && clearInterval(this.listerTimer)
    },
    methods: {
      //确定修改密码
      sureUpdatePwd() {
        console.log('sureUpdatePwd--->', this.updateParams)
        if (!this.updateParams.currentPwd || !this.updateParams.newPwd || !this.updateParams.againPwd) {
          Message({
            message: !this.updateParams.currentPwd ? '请输入您的当前密码~' : (!this.updateParams.newPwd ? '请输入您的新密码' : '请再次输入密码确认'),
            type: 'error'
          });
          return;
        }
        if (this.updateParams.currentPwd.length < 6 || this.updateParams.newPwd.length < 6 || this.updateParams.againPwd.length < 6) {
          Message({
            message: '密码长度不得小于6位数',
            type: 'error'
          });
          return;
        }
        if (this.updateParams.newPwd != this.updateParams.againPwd) {
          Message({
            message: '两次密码不一致',
            type: 'error'
          });
          return;
        }
        api.updatePwd({
          old_pass_word: this.updateParams.currentPwd,
          new_pass_word: this.updateParams.newPwd,
        }
        ).then(async (res) => {
          //console.log('密码密码', res)
          if (res.code == 200) {
            Message({
              message: '修改成功',
              type: 'success'
            });
            let mac = await getMacMethod()
            let res = await api.logout({
              aunique_id: mac
            })
            if (res.code == 200) {
              setTimeout(() => {
                localStorage.setItem('token', '')
                router.push('/login')
                resizeTo(800, 600)
              }, 2000)
            }
          } else {
            Message({
              message: res.msg,
              type: 'error'
            });
          }
        })
      },
      sureUpdateEmail(){ //修改邮箱
        if (!this.emailParam.email || !this.emailParam.emailCode) {
          Message({
            message: !this.emailParam.emailCode ?'请输入邮箱验证码': '请输入新邮箱',
            type: 'error'
          });
          return;
        }

        if (!checkOut('email', this.emailParam.email)) {
            Message({
                message: '新邮箱格式有误，请检查',
                type: 'error'
            });
            return
        }

        if (this.emailParam.emailCode.length < 6) {
          Message({
            message: '邮箱验证码有误，请检查',
            type: 'error'
          });
          return;
        }

        api.updateEmail({
          email: this.emailParam.email,
          code: this.emailParam.emailCode,
        }
        ).then(async (res) => {
          if (res.code == 200) {
            Message({
              message: '修改成功',
              type: 'success'
            });
            this.getInfo()
            this.emailParam={
              email: '',
              emailCode:''
            }
            this.isUpdateEmail=false;
          } else {
            Message({
              message: res.msg,
              type: 'error'
            });
          }
        })
      },
      //修改密码弹窗
      updatePwd() {
        this.isUpdatePwd = true;
        this.updateParams = {
          currentPwd: '',
          newPwd: '',
          againPwd: '',
        }
      },
      updateEmail(){ //修改邮箱弹窗
        this.isUpdateEmail = true;
        this.emailParam={
          email: '',
          emailCode:''
        }
      },
       //发送邮箱
       sendMethod() {
          let that = this;
          if (!that.info.email) {
              Message({
                  message: '邮箱有误',
                  type: 'error'
              });
              return;
          }
          if (that.isDisabled) {
              let time = 60
              that.isDisabled = false
              that.timer1 = setInterval(() => {
                  if (time == 0) {
                      clearInterval(that.timer1);
                      that.isDisabled = true;
                      that.sendText = '发送验证码'
                  } else {
                      that.sendText = time + 'S重新发送'
                      time--;
                  }
              }, 1000)
              api.sendEmail({ email: that.info.email,type:1 }).then(res => {
                  if (res.code == 201) {
                      that.timer && clearInterval(that.timer);
                      that.sendText = '发送验证码';
                      that.isDisabled = true;
                      // return;
                  }
                  Message({
                      message: res.code == 200 ? res.response : res.msg,
                      type: res.code == 200 ? 'success' : 'error'
                  });
              })
          }
      },
      //加密密码
      maskPassword(str) {
        return str.replace(/./g, '*'); // 使用星号替换每一个字符
      },
      //上传联系方式
      sureUpload() {
        let that = this;
        console.log('sureUpload--->', that.inputWx, that.$refs.uploadWx.imageUrl)
        if (!that.$refs.uploadWx.imageUrl || !that.inputWx) {
          Message({
            message: !that.$refs.uploadWx.imageUrl ? '请上传微信二维码~' : '请输入微信号~',
            type: 'error'
          });
          return;
        }
        api.setContact({
          wechat: that.inputWx,
          wechat_image: that.$refs.uploadWx.imageUrl
        }).then(res => {
          // console.log('setContact--->',res)
          that.isLock = false;
          let isError = res.code == 200 ? true : false
          // if (res.code == 200) {
          Message({
            message: isError ? '设置成功~' : res.msg,
            type: isError ? 'success' : 'error',
          });
          // }
        })
      },
      //取消邀请代理
      // cancelInviteMethod() {
      //   console.log('cancelInviteMethod')
      //  // this.isShowInvite = false
      //   !localStorage.getItem('isAgree') && this.getAgree(); //重新获取用户信息
      // },
      //绑定代理商
      bindInviteMethod(type) {
        let that = this;
        if (type == 'exit') {  //退出
          that.changeMenu('exit')
        }
        if (type == 'sure') {  //确定代理
          that.getInfo(); //重新获取用户信息
        }
        if (type == 'cancle') {//取消邀请代理
          !localStorage.getItem('isAgree') && this.getAgree(); //重新获取用户信息
        }
        // if (!that.inviteCode || (that.inviteCode && !that.inviteCode.trim())) {
        //   Message({
        //     message: '请输入邀请码~',
        //     type: 'error'
        //   });
        //   return;
        // }
        // api.bindInviteCode({
        //   invite_code: that.inviteCode
        // }).then(res => {
        //   console.log('bindInviteCode-->', res)
        //   if (res.code == 200) {
        //     that.inviteCode = '';
        //     that.isShowInvite = false;
        //     that.getInfo(); //重新获取用户信息
        //   } else {
        //     Message({
        //       message: res.msg,
        //       type: 'error'
        //     });
        //   }
        // })
      },
      isShield() {
        this.$refs.shield.keyword = '';
        this.$refs.shield.visibleDown = true;
      },
      //监听心跳
      listenMethod() {
        this.listerTimer && clearInterval(this.listerTimer)
        this.listerTimer = setInterval(() => {
          api.listenJump().then(res => {
            //console.log('res监听心跳', res)
          })
        }, 60 * 1000);

      },
      //txt下载
      updateDown(val) {
        // console.log('修改txt下载', val)
        this.isUpdate = val
      },
      //监听控制台报错
      handleListenerError(event) {
        // console.log('event', event)
      },
      //同意事件
      agreeMethod(index) {
        //console.log('index', index)
        if (index == 1) {
          top.window.require && closeWin();
        }
        if (index == 2) {
          this.isAgree = false;
          localStorage.setItem('isAgree', true)
          //this.checkUpdate();
        }
      },
      //获取协议
      getAgree() {
        let that = this;
        api.getUpdate({
          call_index: 'UseAgreement'
        }).then(res => {
          //console.log('获取协议res', res)
          if (res && res.ext && res.ext.use_agreement) {
            that.isAgree = true;
            that.agreeContent = res.ext.use_agreement;
          }
        })
      },
      //检查更新
      checkUpdate() {
        let that = this;
        that.alertIndex++;
        api.getUpdate({
          call_index: 'Version'
        }).then(res => {
          // console.log('更新时间', res)
          if (res && res.ext && res.ext.version) {
            if (updater.checkVersion(res.ext.version)) {
              //alert(`我被请求${that.alertIndex}次？`)
              that.alertIndex == 1 && MessageBox.alert("当前系统发现新版本,请前往更新~", "提示", {
                confirmButtonText: "立即更新",
                type: "info",
                closeOnClickModal: false,
                showClose: false
              }).then((action) => {
                if (action == 'confirm') {
                  updater.downLoad(res.ext.download_address, function (rate) {
                    // console.log('当前下载进度', rate);
                    rate == 100 && (that.alertIndex = 0)
                    rate && (that.isUpdate = true)
                    rate && that.collectMethod({
                      isCollect: rate == 100 ? false : true,
                      count: rate == 100 ? 0 : rate,
                      type: 'update',
                      total: '100'
                    });
                  }, function (err) {
                    // console.log('异常', err);
                  })
                }
              })

            }

          }
        })
      },
      //获取公告
      async getNotice() {
        let noticeResult = await api.getDownSetting({ call_index: 'Announcement' })
        noticeResult.ext && (this.notice = noticeResult.ext)
      },
      //获取群组设置
      async getGroupSet() {
        let that = this;
        let groupSet =  await api.getDownSetting({ call_index: 'GroupSet' })
        groupSet.ext && that.$store.commit("saveGroupSet", {
            ...groupSet.ext
          });
      },
      isWithinLast30Days(specifiedDate) {
        const currentDate = new Date();
        const daysDiff = Math.abs(currentDate - specifiedDate) / (1000 * 60 * 60 * 24);
        return daysDiff <= 30;
      },
      //获取用户信息
      async getInfo() {
        let that = this;
        let info = await api.getInfo();
        //  let remainnum = await api.getRemainnum();
        console.log("info", info);
        if (info.code == 200) {
          //在这里判断本地存储和远程是否存在
          let save_address = localStorage.getItem('downAdress') ? localStorage.getItem('downAdress') : (info.response.save_address ? info.response.save_address : getPath())//没有远程的时候要获取安装目录地址
          // console.log('save_address', save_address)
          localStorage.setItem('downAdress', save_address)
          that.$store.commit("addInfo", {
            ...info.response,
            save_address: save_address,
            pwd: that.maskPassword(localStorage.getItem('userAccount').split('&&')[1])
          });

          //获取当前登录的代理商信息
          let currentAgent = await api.getAgentContact()
          //获取代理商配置
          let agentSet = await api.getUpdate({
            call_index: 'AgentSet'
          })
          agentSet.ext && that.$store.commit("saveAgentSet", {
            ...agentSet.ext
          });
          currentAgent?.code == 200 && that.$store.commit("currentSet", currentAgent?.response);
          //是否填写邀请码
          if (!info.response.inviter_agent_id) {
            that.$refs.fillInvite.currentAgentSet = agentSet.ext
            that.$refs.fillInvite.isShowInvite = true;
            // that.isShowInvite = true;
            return;
          }

          if (currentAgent?.code == 200) {
            if (currentAgent?.response?.id) {
              if (!currentAgent?.response?.wechat || !currentAgent?.response?.wechat_image) {
                that.isLock = true;
                return;
              }

            }
          }
          
          var currentDate = new Date();
				  currentDate.setMonth(currentDate.getMonth() + 6)//半年
          //代理商到期提醒
          if (currentAgent?.response?.expired_time && (new Date(currentAgent?.response?.expired_time).getTime()< currentDate.getTime()) && (new Date(currentAgent?.response?.expired_time).getTime() > new Date().getTime()) && localStorage.getItem('isAgree')) { //如果过期时间大于当前时间
              var days = that.getDaysBetweenDates(new Date(),new Date(currentAgent?.response?.expired_time))
              let lastTipsDate = localStorage.getItem('LASTEXPIREDTIP'+ currentAgent?.response?.id)
              if(days< 30) {
                if(!lastTipsDate || this.getDaysBetweenDates(new Date(),lastTipsDate)>1) {
                    localStorage.setItem('LASTEXPIREDTIP'+ currentAgent?.response?.id, that.formatDate(new Date()))
                    MessageBox.alert(agentSet.ext.agent_due_tips?.replace('{expired_time}',that.formatDate(new Date(currentAgent?.response?.expired_time))), '代理商提醒', {
                      confirmButtonText: '确定',
                    })
                }
              }
              else {
                if(!lastTipsDate || that.getDaysBetweenDates(new Date(),lastTipsDate) > 30){
                   localStorage.setItem('LASTEXPIREDTIP'+ currentAgent?.response?.id, that.formatDate(new Date()))
                   MessageBox.alert(agentSet.ext.agent_due_tips?.replace('{expired_time}',that.formatDate(new Date(currentAgent?.response?.expired_time))), '代理商提醒', {
                      confirmButtonText: '确定'
                    })
                }
              }
          }

          !localStorage.getItem('isAgree') && that.getAgree()
        }
      },
      
      formatDate(date) {
        var year = date.getFullYear()
        var month = ('0' + (date.getMonth() + 1)).slice(-2)
        var day = ('0' + date.getDate()).slice(-2)
        var formattedDate = year + '-' + month + '-' + day
        return formattedDate
			},
      getDaysBetweenDates(startDate, endDate) {
          var start = new Date(startDate)
          var end = new Date(endDate)
          var timeDiff = Math.abs(end.getTime() - start.getTime())
          var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
          return diffDays
			},

      //下载不下来时允许用户关闭弹窗
      closeMethod() {
        let that = this;
        //console.log('关闭弹窗', that.isDown, that.isUpdate, that.isDownTxt)
        if (that.isDown && !that.isUpdate && !that.isDownTxt) {
          MessageBox.confirm("确定要中断下载操作吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then((action) => {
            if (action == "confirm") {
              //closeWin()
              that.$refs.components.loadIndex = -1;
              that.$refs.components.isDown = false;
              //that.$refs.components.sureDown('','','','',true)
            }
          });
        }
      },
      closeCircle(data) {
        this.isPause = data;
      },
      //恢复采集
      resumeMethod(data) {
        //console.log('resumeMethod', data)
        if (data) {
          this.isContinue = false;
          this.$store.commit("changeCollect", false);
          this.$refs.components.resumeMethod();
        } else {
          // console.log('进的这里???')
          this.isPause = false;
          this.isContinue = true;
          this.collectMethod({
            isCollect: true,
            count: -1,
          });
        }
      },
      //采集按钮
      stopMetod(index) {

        let that = this;
        that.$store.commit("changeCollect", true);
        if (index == 1) {
          that.isPause = true;
        }
        that.isContinue = false;
        console.log('停止采集', that.initCountNew, that.count)
        that.collectMethod({
          isCollect: false,
          count: index == 1 ? -1 : 0,
          isrest: index == 1 ? true : false,
        });
        index == 1
          ? that.$refs.components.clear(true)
          : that.$refs.components.rest(true);
      },
      //初始化count
      initCount() {
        this.initCountNew = 0;
        this.count = 0;
      },
      //显示采集动画
      collectMethod(data) {
        let that = this;
        that.isDownTxt = data && data.isShowCount ? true : false;
        that.isCollect = data && data.isCollect ? true : false;
        that.isDown =
          data && data.isCollect && data.type == "down" ? true : false;
        if (!data.count) {
          that.count = data.total ? (data.isDown ? that.count : data.total) : that.count;
          that.initCountNew = !that.isContinue ? (that.isPause ? that.count : 0) : that.count;
          if(data.type =="update") {//客户端更新
              MessageBox.alert("新版本下载完成","温馨提示",{
                  confirmButtonText: "确定",
                  callback: () => {
                    that.count = 0;
                    data.isrest
                      ? this.$refs.components.clear(true)
                      : this.$refs.components.rest(true);
                  }
              })
          }
          else if(data.type =="down") {
            MessageBox.confirm( `下载结束，共下载${that.count}条数据，失败${data.errLen ? data.errLen : (data.total ? (data.total - that.count) : 0)}条数据，是否打开本地文件夹？`, '提示', {
                  confirmButtonText: '打开',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  that.count = 0;
                  data.isrest ? this.$refs.components.clear(false): this.$refs.components.rest( false);
                  top.window.require && openPath(this.info.save_address);
                }).catch(() => {
                  that.count = 0;
                  data.isrest ? this.$refs.components.clear(false): this.$refs.components.rest( false);
                });
          }
          else{
            MessageBox.alert("采集结束，共采集${that.count}条数据","温馨提示",{
                  confirmButtonText: "确定",
                  callback: () => {
                    that.count = 0;
                    data.isrest
                      ? this.$refs.components.clear(true)
                      : this.$refs.components.rest(true);
                  }
              })
          }
          return;
        }
        that.initCountNew = that.count;
        //console.log('that.initCountNew', that.initCountNew)
        if (data.count && data.count != -1) {
          //console.log('采集动画count', that.count)
          data.type && data.type == 'update' ? (that.count = data.count) : (that.count += data.count)
        }
        data.total &&
          data.type == "down" &&
          (that.downTotal = data.total - that.initCountNew);
        //console.log('采集动画结束count', data.total, that.count, that.downTotal, that.initCountNew)
      },
      //菜单事件
      menuClick(index) {
        console.log('menuClick', index)
        let that = this;
        top.window.require && index == 0 && minWin();
        if (index == 1) {
          top.window.require && maxWin();
          that.isMax = true;
        }
        if (index == 2) {
          top.window.require && restore();
          that.isMax = false;
        }
        (index == 3 || index == 4) &&
          MessageBox.confirm("确定要退出软件吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(async (action) => {
            if (action == "confirm") {
              let mac = await getMacMethod()
              api.logout({
                aunique_id: mac
              }).then(res => {
                if (res.code == 200) {
                  if (index == 3) {
                    top.window.require && closeWin();
                  }
                  //index == 3 && top.window.require && closeWin();
                  if (index == 4) {
                    localStorage.setItem('token', '')
                    router.push('/login')
                    resizeTo(800, 600)

                  }
                  //index==4&&router.push('/login')
                }
                // res.code == 200 && top.window.require && closeWin();
              })
            }
          });
      },

      //提示用户未充值
      titleMethod() {
        let that = this;
        MessageBox.alert(that.rechangeMsg, "温馨提示", {
          confirmButtonText: "去充值",
          callback: (action) => {
            if (action == "confirm") {
              that.current = "recharge";
              that.currentTabComponent = "recharge";
            }
          },
        });
      },

      //切换菜单
      async changeMenu(key) {
        this.current = key;
        this.isPause = false;
        // if (key == "channel" && infoResult.code == 200 && infoResult.response && !infoResult.response.user_type) {
        //   Message({
        //     message: '暂无权限~',
        //     type: 'error'
        //   });
        //   return
        // }

        if (key == 'contactSuperiors') {
          let infoResult = await api.getInfo()
          if (!infoResult.response.inviter_agent_id) {
            // let contact=await api.
            // this.isShowInvite = true;
            this.$refs.fillInvite.currentAgentSet = this.currentAgentSet
            this.$refs.fillInvite.isShowInvite = true;

          } else {
            this.isShowInviteContact = true
          }
          return;
        }
        this.getInfo() //菜单校验代理信息
        if(key=='wxcollect') {
          this.getGroupSet();//重新获取配置
        }

        if (key == 'code') {
          let getCode = await api.getDownSetting({
            call_index: 'H5QrCode'
          })
          if (getCode.ext) {
            this.$refs.code.visibleCode = true;
            this.$refs.code.path = getCode.ext;
          }
          //console.log('获取移动端code', getCode)
          return;
        }
        if (key == 'myMessage') {
          this.myMessageVisible = true;
          return;
        }
        if (key == 'exit') {
          this.menuClick(4)
          return;
        }
        key != "mydown"
          ? (this.currentTabComponent = key)
          : MessageBox.confirm("此操作会打开本地文件，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then((action) => {
            console.log("进回调了");
            if (action == "confirm") {
              top.window.require && openPath(this.info.save_address);
            }
          });
      },
      //延迟计算高度
      // countMethod() {
      //     let that = this;
      //     setTimeout(() => {
      //         that.contentH = (document.documentElement.offsetHeight - that.$refs.topContent.offsetHeight) + 'px'
      //         console.log('contentH', that.contentH)
      //         that.timer = false
      //     }, 10)
      // }
    },
  };
</script>
<style lang="less" scoped>
  /* 采集动画 */

  .collectAction {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  }

  .collectContent {
    padding: 37px 0;
    position: absolute;
    width: 312px;
    background: #fff;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .collectTitle {
    padding: 10px 0 18px 0;
  }

  .collectMin {
    width: 70px;
    position: absolute;
    left: -25px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 100%;
    }

    p {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }

  .collectImg {
    width: 99px;
    margin: auto;

    img {
      width: 100%;
    }

    span,
    p {
      display: inline-block;
      color: #e2e2e2;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
    }
  }

  .collectBtn {
    padding-top: 20px;

    span {
      width: 110px;
      line-height: 30px;
      background: #f1f1f1;
      border-radius: 25px;
      text-align: center;
      margin: 0 20px;
    }
  }

  .indexPage {
    width: 100%;
    height: 100vh;
    /* background: #0b41d7; */
    padding: 0px 5px 5px 5px;
  }

  .indexActive {
    background: #0b41d7;
  }

  .indexNav {
    padding: 18px 18px 0 18px;
  }

  .indexTop {
    width: 100%;
    background: url("../src/assets/header.jpg") no-repeat;
    background-size: 100% 100%;

    .navTitle {
      span {
        margin-left: 13px;
      }
    }

    .navLogo {
      width: 25px;
      margin-right: 10px;
    }

    .navRight {
      -webkit-app-region: no-drag;



      span:first-child {
        margin-right: 14px;
      }

      /* span:nth-child(2) {
                margin-right: 20px;
            } */

      .navLine {
        width: 28px;
        height: 3px;
      }

      .navClose {
        width: 14px;
      }

      .navMin {
        width: 18px;
        margin: 0 13px 0 0px;
      }

      .navSign {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 30px;

        img {
          width: 16px;
        }
      }
    }

    .indexMenu {
      padding: 0 18px;
      margin-top: 4px;

      li {
        width: 138px;
        /* width: 130px; */
        padding: 24px 0 18px 0;
        transition: 0.3s linear;
        cursor: pointer;

        img {
          width: 56px;
          margin: auto;
          margin-bottom: 4px;
          transition: 0.3s linear;
        }

        p {
          text-align: center;
        }

        &:hover {
          /* transform: translateY(-10px); */
          background-image: linear-gradient(#0b41d7, #0506b8);

          img {
            transform: rotate(360deg);
          }
        }
      }

      .cur {
        background-image: linear-gradient(#0b41d7, #0506b8);
      }
    }
  }

  .indexContent {
    /* background: #0b41d7;
   padding: 0px 5px; 
   height: calc(100vh - 175px); */
    height: calc(100vh - 180px);
    /* padding-bottom: 5px; */
  }

  .contentBottom {
    background: #fff;
    height: 100%;
    padding: 10px 18px;
  }

  .indexAbsolute {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent;
  }


  /* 蒙层 */
  /* 协议 */
  .agreeContent {
    width: 400px !important;
    padding: 20px 20px !important;

    .agreeBtn {
      background: #0B41D7 !important;

    }

    .agreeText {
      width: 100%;
      max-height: 400px;
      overflow-y: auto;
      padding: 10px 0;
    }
  }

  .hideContent {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .warp {
    width: 100%;
    height: 100%;
    overflow: hidden;

    ul.item {
      width: auto;

      li {
        float: left;
        margin-right: 10px;
      }
    }
  }

  .notice {
    padding: 2px 5px;
    background: #2b52f4;
    color: #fff;
    position: relative;
    z-index: 1;
    border-radius: 3px;
    display: inline-block;
    width: 270px;
    /* overflow: hidden; */
    height: 20px;
    margin-left: 10px;

  }

  .notice:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: 7px;
    left: -3px;
    background: #2b52f4;
    transform: rotate(45deg);
    z-index: 0;

  }

  /* 我的消息 */
  .myMessageContent li {
    padding: 10px 0;
    border-bottom: 1px solid #e3e5e8;
  }

  .myMessageContent li:last-child {
    border-bottom: 0;
  }

  .myMessageContent li p {
    color: #666;
    width: calc(100% - 100px);

  }

  .myMessageContent li p span {
    color: #2b52f4;
    margin-left: 10px;
  }

  .myMessageContent li p:first-child {
    font-weight: bold;
    color: #000;
    width: 90px;
  }

  /deep/ .el-dialog__body {
    padding: 0px 20px 40px 20px;
  }

  .inviteContent {
    width: 100%;

  }

  .inviteContent p {
    color: red;
    margin: 10px 0;
  }

  /deep/ .inviteDialog .el-dialog__body {
    padding: 0px 20px 20px 20px;
  }

  /deep/ .inviteDialog .el-dialog__header {
    text-align: center;
  }

  .inviteDialog .dialog-footer {
    text-align: center;
    display: block;
    margin-top: 20px;
  }

  .inviteDialog .el-button {

    padding: 10px 20px;

  }

  .lockContent {
    margin-top: 20px;
  }

  .lockContent li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;

    span {
      width: 90px;
    }
  }

  /deep/ .lockContent .el-input {
    width: 70%;
  }

  .lockTitle {
    margin-bottom: 10px;
    color: red;
  }

  /deep/ .lockDialog .el-dialog__body {
    padding: 0px 20px 10px 20px;
  }

  /deep/ .lockDialog .el-button {
    padding: 8px 20px;
  }

  /deep/ .lockDialog .el-dialog__footer {
    text-align: center
  }

  @media (min-width: 1660px) {
    .indexTop {
      .navLogo {
        width: 33px;
        margin-right: 15px;

        span {
          margin-left: 12px;
        }
      }

      .navRight {
        .navClose {
          width: 15px;
        }

        .navSign {
          width: 20px;
          margin: 0 30px 0 50px;
        }
      }
    }
  }

  .updatePwd,.updateEmail {
    li {
      display: block;

      p {
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
      }
    }

    .lockContent{
      width: 100%;
    }
  }


  /deep/ .updatePwd .el-dialog__footer, /deep/ .updateEmail .el-dialog__footer {
    text-align: center;
    padding-top: 0;
  }

  /deep/ .updatePwd .el-input, /deep/ .updateEmail .el-input {
    width: 100%;
  }
</style>